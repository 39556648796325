import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from 'components/form'
import { Button } from 'components/button'
import { styled } from 'styles/stitches.config'
import { useForm } from 'react-hook-form'
import useAuth from 'hooks/use-auth'
import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { Box, Flex } from 'components/elements'
import { defaultImage, NotificationMessages, PHONE_VALIDATION, RegPattern, ValidationErrors } from 'utils/enum'
import { UploadImage } from 'components/uploadImage'
import { errorMessage } from 'utils/function'
import SnackBar from 'components/SnackBar'
import { FormControl } from '@mui/material'
import './style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { StyledDropdownWrap } from 'components/form/Textfield.styles'
import { FlexWrapCSS, inputStyle, NegativeMargin, wrapStyle } from 'views/Support/SupportForm'
import { useEditProfile } from '../api/editProfile'

export type ModalFormFields = {
  oldSentinelOneApiKey: string
  sentinelOneApiKey: string
}

type EditProfileFormType = {
  firstName: string
  lastName: string
  companyName: string
  companyAddress: string
  companyWebsite: string
  phoneNum: string
  email: string | undefined
  annualRevenue: number | null
}

export const EditProfileForm = () => {
  const { user } = useAuth()
  const { mutate, isLoading, isSuccess } = useEditProfile()
  const [errorModal, setErrorModal] = useState(false)
  const [phoneNum, setPhoneNumber] = useState<string>(user?.phoneNum || '')
  const [hasError, setHasError] = useState('')
  const [notification, setNotification] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
    // TODO: refactor default values, also add validation
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNum: user?.phoneNum || '',
      email: user?.email || '',
      companyName: user?.companyName || '',
      companyAddress: user?.companyAddress || '',
      companyWebsite: user?.companyWebsite || '',
      sentinelOneApiKey: user?.sentinelOneAPiKey || '',
      annualRevenue: user?.annualRevenue || null,
    },
  })

  useEffect(() => {
    if (user?.imageUrl) {
      setPreview(user?.imageUrl || '')
    }
  }, [user])

  const [submitError, setSubmitError] = useState('')
  const [selectedFile, setSelectedFile] = useState<Blob>()
  const [preview, setPreview] = useState('')

  const onSubmit = (data: EditProfileFormType) => {
    if (hasError !== '') return

    setSubmitError('')
    const bodyFormData = new FormData()
    bodyFormData.append('firstName', data.firstName)
    bodyFormData.append('lastName', data.lastName)
    bodyFormData.append('companyName', data.companyName)
    bodyFormData.append('companyAddress', data.companyAddress)
    bodyFormData.append('companyWebsite', data.companyWebsite)
    bodyFormData.append('phoneNum', data.phoneNum)
    // bodyFormData.append('annualRevenue', data.annualRevenue !== null ? data.annualRevenue.toString() : '')

    if (selectedFile) {
      bodyFormData.append('user_profile_image', selectedFile)
    }

    mutate(bodyFormData, {
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          setErrorModal(!!submitError)
          setSubmitError(errorMessage(error))
        }
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setNotification(true)
      setTimeout(() => {
        setNotification(false)
      }, 2000)
    }
  }, [isSuccess])

  const PhoneNumber = (newValue: string | undefined) => {
    if (newValue !== undefined) {
      setPhoneNumber(newValue)
      setValue('phoneNum', newValue, { shouldValidate: true })
      if (isValidPhoneNumber(newValue)) setHasError('')
      else {
        setHasError(PHONE_VALIDATION.PHONEERROR)
      }
    }
  }

  return (
    <>
      <Flex as="form" css={NegativeMargin} direction="column" onSubmit={handleSubmit(onSubmit)}>
        <UploadImage defaultImage={defaultImage.admin} preview={preview} setPreview={setPreview} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
        <Flex css={FlexWrapCSS}>
          <Box css={wrapStyle}>
            <TextField
              placeholder="Enter First Name"
              {...register('firstName')}
              label="First Name"
              defaultValue={user?.firstName || ''}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              css={inputStyle}
            />
          </Box>
          <Box css={wrapStyle}>
            <TextField
              placeholder="Enter Last Name"
              {...register('lastName')}
              label="Last Name"
              defaultValue={user?.lastName || ''}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              css={inputStyle}
            />
          </Box>
        </Flex>
        <Flex css={FlexWrapCSS}>
          <Box css={wrapStyle}>
            <TextField {...register('email')} placeholder="Enter Email" label="Email" fieldDisabled css={inputStyle} />
          </Box>
          <Box css={wrapStyle}>
            <StyledDropdownWrap style={{ margin: 0, maxWidth: '520px' }} className="phoneNumber">
              <FormControl fullWidth className={`phoneNumber ${errors.phoneNum && 'phoneNumberError'}`}>
                <label className="phone_label">Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  placeholder="Phone number"
                  value={phoneNum}
                  onChange={newValue => {
                    PhoneNumber(newValue)
                  }}
                />
              </FormControl>
              <Box as="p" className="errorText">
                {hasError}
              </Box>
              {!!errors.phoneNum && (
                <Box as="p" className="errorText">
                  {errors.phoneNum?.message}
                </Box>
              )}
            </StyledDropdownWrap>
          </Box>
        </Flex>
        <Flex css={FlexWrapCSS}>
          <Box css={wrapStyle}>
            <TextField
              {...register('companyName')}
              placeholder="Enter Company Name"
              label="Company Name"
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
              css={inputStyle}
            />
          </Box>
          <Box css={wrapStyle}>
            <TextField
              {...register('companyWebsite')}
              placeholder="Enter Company Website"
              label="Company Website"
              error={!!errors.companyWebsite}
              helperText={errors.companyWebsite?.message}
              css={inputStyle}
            />
          </Box>
        </Flex>
        <Flex css={FlexWrapCSS}>
          <Box css={wrapStyle}>
            <TextField
              {...register('companyAddress')}
              placeholder="Enter Company Address"
              label="Company Address"
              error={!!errors.companyAddress}
              helperText={errors.companyAddress?.message}
              css={inputStyle}
            />
          </Box>
          {/* TODO:Might need it later */}
          {/* <Box css={wrapStyle}>
            <TextField
              {...register('annualRevenue')}
              placeholder="Enter your Annual Revenue($)"
              label="Annual Revenue"
              error={!!errors.companyAddress}
              helperText={errors.companyAddress?.message}
              css={inputStyle}
            />
          </Box> */}
        </Flex>

        <Flex css={ModalbuttonWrapCss} style={{ padding: 0 }}>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Flex>

        <SnackBar open={notification && isSuccess} onClose={() => setNotification(false)} text={NotificationMessages.PROFILE_UPDATE_SUCCESS} severity={'success'} />
        <SnackBar open={errorModal} onClose={() => setErrorModal(!submitError)} text={submitError} severity={'error'} />
      </Flex>
    </>
  )
}

const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(ValidationErrors.FIRST_NAME)
    .matches(RegPattern.NO_WHITE_SPACE, ValidationErrors.NO_WHITE_SPACE)
    .matches(RegPattern.FIRST_NAME_VALIDATION, ValidationErrors.FIRST_NAME_VALIDATION),

  lastName: Yup.string()
    .required(ValidationErrors.LAST_NAME)
    .matches(RegPattern.WHITE_SPACE, ValidationErrors.WHITE_SPACE)
    .matches(RegPattern.LAST_NAME_VALIDATION, ValidationErrors.LAST_NAME_VALIDATION),

  companyName: Yup.string()
    .required(ValidationErrors.COMPANY_NAME)
    .matches(RegPattern.WHITE_SPACE, ValidationErrors.WHITE_SPACE)
    .matches(RegPattern.COMPANY_NAME_VALIDATION, ValidationErrors.COMPANY_NAME_VALIDATION),
  companyAddress: Yup.string()
    .required(ValidationErrors.ADDRESS)
    .matches(RegPattern.WHITE_SPACE, ValidationErrors.WHITE_SPACE)
    .matches(RegPattern.ADDRESS_VALIDATION, ValidationErrors.ADDRESS_VALIDATION),
  companyWebsite: Yup.string().required(ValidationErrors.WEBSITE_URL).matches(RegPattern.URL_VALIDATION, ValidationErrors.WEBSITE_URL_VALIDATION),
  phoneNum: Yup.string().required(ValidationErrors.PHONE),
  email: Yup.string(),
  // annualRevenue: Yup.number().typeError(ValidationErrors.ANNUAL_REVENUE_TYPEERROR).positive(ValidationErrors.ANNUAL_REVENUE_POSITIVE).required(ValidationErrors.REQUIRED),
})

export const OverflowScroll = {
  'overflowX': 'auto',

  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '$white1A',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '$white',
    borderRadius: '4px',
  },
}

export const ButtonWrap = styled('div', {
  'display': 'flex',
  'alignItems': 'flex-end',
  'gap': 15,

  '&.circle--icon': {
    button: {
      backgroundColor: '$primary',
      padding: '.4rem',
      borderRadius: '50%',

      svg: {
        fontSize: '1.15rem',
      },
    },
  },
})

export const ModalbuttonWrapCss = {
  'justifyContent': 'center',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'width': '100%',
  'padding': '1.5rem 0 0',
  'gap': '20px',

  '@sm': {
    padding: '2.5rem 12px 0',
  },

  '&.item--left': {
    justifyContent: 'flex-start',
  },

  '&.noPadding': {
    padding: 0,
  },
}

export const FormBtnWrap = {
  '[class*="size-lg"]': {
    flexGrow: 1,
  },
}

export const ModalForm = styled('form', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 28,
})

export const KeyButtonCSS = {
  minWidth: 'unset',
  flexGrow: 1,
}

export const ErrorBox = styled('div', {
  color: '$red',
  textAlign: 'center',
  margin: 'auto',
  width: '100%',
  height: '20px',
})
export const LinkCss = {
  display: 'block',
  color: '$primary',
  mt: 8,
  height: 'auto',
  border: 'none',
  bgColor: 'transparent',
}
